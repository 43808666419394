@import "./0_mediaquery";
@import "./variables";

.departments {
  height: 100vh;
  overflow-y: auto;

  .department {
    background-color: white;

    .container {
      max-width: 85%;
      width: 100%;
      margin: auto;

      .text {
        color: gray;
        font-size: medium;
        font-weight: 400;

        font-family: 'Arial', sans-serif;
        max-width: 800px;
        /* Optimal line length for readability */
        margin: 0 auto;
        /* Center the content */
        padding: 20px;
        color: #333;
        /* Dark gray for better readability */
        background-color: #f9f9f9;
        /* Light background */
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        p {
          line-height: 1.7;
          font-size: medium;
          font-weight: 400;
          margin: 10px 0 0 0;
        }

        ol {
          padding-left: 20px;
          margin-bottom: 1em;
          list-style-type: none;

          li {
            font-size: 1em;
            margin-bottom: 0.5em;
            text-indent: -24px;
            /* Negative indent for the first line */
            padding-left: 20px;
          }

          li:before {
            content: counter(item) ". ";
            counter-increment: item;
            color: $primarylight;
            margin-right: 5px;
            // text-indent: -40px;
          }
        }

        ol {
          counter-reset: item;
        }
      }

      .slideshow-container {
        position: relative;
        height: 100%;
        min-height: 400px;

        .slideshow-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
      
          .change {
            position: relative;
            height: 100%;
            width: 100%;
            padding: 20px;

            img {
              max-height: 100%;
              height: 100%;
              width: 100%;
              max-width: 50em;
              object-fit: cover;
              border-radius: 5px;
            }

            p {
              line-height: 1;
              font-size: small;
              font-weight: 400;
              color: black;
              margin: auto;
              text-align: center;
            }
          }
        }
      
        .slideshow-image.active {
          opacity: 1;
        }
      }

      // img {
      //   max-width: 100%;
      //   width: 100%;
      //   object-fit: contain;
      //   object-position: center;
      // }

      h3 {
        color: $primary;
        font-size: medium;
        margin: 10px 0 10px 0;
        font-weight: 400;
      }

      h1 {
        color: $secondary;
        font-size: xx-large;
        margin: 10px 0 10px 0;
        padding: 0 0 44px 0;
        position: relative;
        text-align: left !important;
        width: fit-content;
        display: block;
      }

      h1::before {
        content: "";
        position: absolute;
        left: 7%;
        bottom: 16px;
        width: 150px;
        height: 14px;
        transform: skew(-12deg) translateX(-50%);
        background: #ecc115;
        z-index: 2;
      }

      h2 {
        color: $primary;
        font-size: x-large;
        margin: 10px 0 10px 0;
        padding: 0 0 44px 0;
        position: relative;
        text-align: left !important;
        width: fit-content;
        display: block;
      }

      h2::before {
        content: "";
        position: absolute;
        left: 7%;
        bottom: 16px;
        width: 150px;
        height: 14px;
        transform: skew(-12deg) translateX(-50%);
        background: #ecc115;
        z-index: 2;
      }
    }
  }

  @include maxwidth(mobile) {
    .department {
  
      .container {
        .slideshow-container {
          // position: static;
          height: 300px;
      
          .slideshow-image {
            .change {
              padding: 0 0 20px 0;
            }
          }
        }

        @include maxwidth(mobile) {
          .div2equal {
            grid-template-columns: 1fr !important;
          }
        }
      
        @include maxwidth(tablet) {
          .div2equal {
            grid-template-columns: 1fr !important;
          }
        }
  
      }
    }
  }

  @include maxwidth(tablet) {
    .dsections {
      grid-template-columns: 1fr 1fr !important;
    }
  }
}

@keyframes zoom {
  0% {
    scale: 0.8;
  }
  100% {
    scale: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
