@import "./_variables";
@import "./_0_mediaquery";
.News{
  height: 100vh;
  overflow-y: auto;
  position: relative;
.newscreate { 
  width: 85%;
  margin: auto;
  padding: 1em;

  .list {
    background-color: white;
    border-radius: 8px;
    padding: 1em;

    .new {
      form {
        width: 100% !important;


        button {
          display: block;
          margin: 1em auto 1em auto;
          padding: 12px;
          width: 80%;
          background-color: $primary;
          color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          border-bottom: 2px solid #60606070;
          border-top: 2px solid #60606070;
          cursor: pointer;
        }
        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }
        .input {
          margin: 10px 0 10px 0;
  
          h4 {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
            width: 100%;
            text-align: left;
          }
  
          input {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606005;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
  
          textarea {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606005;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          textarea:focus {
            border-bottom: 2px solid $primary;
          } 
        }
        .select {
          margin: 10px 0 10px 0;
  
          h4 {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
            width: 100%;
            text-align: left;
          }
  
          select {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606005;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }
      }
    }
  }
}
}
@keyframes zoom {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}
