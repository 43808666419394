@import "./0_mediaquery";
@import "./variables";

.aboutpage {
  height: 100vh;
  overflow-y: auto;

  .about {
    padding: 2em;
    background-color: white;

    .container {
      max-width: 85%;
      width: 100%;
      margin: auto;

      img {
        max-width: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
      }

      h3 {
        color: $primary;
        font-size: medium;
        margin: 10px 0 10px 0;
        font-weight: 400;
      }

      h1 {
        color: $secondary;
        font-size: xx-large;
        margin: 10px 0 10px 0;
        padding: 0 0 44px 0;
        position: relative;
        text-align: left !important;
        width: fit-content;
        display: block;
      }

      h1::before {
        content: "";
        position: absolute;
        left: 7%;
        bottom: 16px;
        width: 150px;
        height: 14px;
        transform: skew(-12deg) translateX(-50%);
        background: #ecc115;
        z-index: 2;
      }

      h2 {
        color: $primary;
        font-size: x-large;
        margin: 10px 0 10px 0;
        padding: 0 0 44px 0;
        position: relative;
        text-align: left !important;
        width: fit-content;
        display: block;
      }

      h2::before {
        content: "";
        position: absolute;
        left: 7%;
        bottom: 16px;
        width: 150px;
        height: 14px;
        transform: skew(-12deg) translateX(-50%);
        background: #ecc115;
        z-index: 2;
      }

      p {
        line-height: 1.7;
        font-size: medium;
        font-weight: 400;
        color: gray;
        margin: 10px 0 10px 0;
      }
    }
  }

  @include maxwidth(mobile) {
    .about {
      padding: 2em;
      background-color: white;
  
      .container {
        max-width: 100%;
        width: 100%;
        margin: auto;
  
      }
  
      @include maxwidth(mobile) {
        .div2equal {
          grid-template-columns: 1fr !important;
        }
      }
  
      @include maxwidth(tablet) {
        .div2equal {
          grid-template-columns: 1fr !important;
        }
      }
    }
  }

  @include maxwidth(tablet) {
    .dsections {
      grid-template-columns: 1fr 1fr !important;
    }
  }
}

@keyframes zoom {
  0% {
    scale: 0.8;
  }
  100% {
    scale: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
