@import "./_variables";
@import "./0_mediaquery";

.ContactUsPage {
  height: 100vh;
  overflow-y: auto;

  .landing_hero {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .nhero {
      height: 70vh;
      position: relative;
      background-color: rgba($color: #000000, $alpha: 0.5);
      z-index: 999;

      .slideshow-container {
        position: relative;
        height: 70vh;
      }

      .slideshow-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;

        img {
          max-height: 70vh;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .content {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 100%;
          display: flex;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.3);
          padding: 2em;
          opacity: 0;
          transform: translateY(50px);
          transition: opacity 0.5s ease, transform 0.5s ease;
        }

        .content.animation-active {
          opacity: 1;
          transform: translateY(0);
        }

        .left {
          display: grid;
          grid-template-rows: auto 1fr auto;
          gap: 2em;
          text-align: center;
          color: white;
          height: 100%;
          max-width: 85%;
          width: 100%;
          margin: auto;

          p {
            padding: 0 0 34px 0;
            position: relative;
            text-align: left !important;
            width: fit-content;
            font-weight: 400;
          }

          p::before {
            content: "";
            position: absolute;
            left: 7%;
            bottom: 16px;
            width: 150px;
            height: 14px;
            transform: skew(-12deg) translateX(-50%);
            background: #ecc115;
            z-index: -1;
          }

          h1 {
            margin: auto;
            font-family: "Ubuntu", sans-serif;
            font-size: xx-large;
            color: white;
            text-align: center;
            //letter-spacing: 5px;
            //   text-shadow: 0 2px 1px #747474, -1px 3px 1px #767676,
            //     -2px 5px 1px #787878, -3px 7px 1px #7a7a7a, -4px 9px 1px #7f7f7f;
            // }
          }

          p {
            font-size: x-large !important;
            color: white;
          }

          button {
            padding: 10px;
            width: fit-content;
            min-width: 200px;
            display: block;
            margin: auto;
            cursor: pointer;
            color: $primary;
          }
          button:hover {
            background-color: $primary;
            color: white;
          }
        }
      }

      .slideshow-image.active {
        opacity: 1;
      }

      @include maxwidth(tablet) {
        height: fit-content !important;
        padding-top: 100px !important;
        padding-bottom: 50px;

        .div2equal {
        }
      }
    }
    .hero_buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 0;
      margin-bottom: 10px;
      background: red;

      .holding {
        width: 15%;
        height: 100%;
        display: flex;
        justify-content: space-around;

        .radial {
          height: 17px;
          width: 17px;
          border: solid 4px $primary;
          border-radius: 50%;
          cursor: pointer;
          transition: 300ms all ease-in-out;
        }
        .radial:hover {
          border: 0px;
          background: $primary;
        }
      }
    }

    .main {
      z-index: 1;
      overflow: hidden;
      height: calc(100% - 64px);
      position: absolute;
      top: 64px;
      left: 0;
      width: 100%;

      .wrp {
        height: 100%;
        width: 100%;
        position: relative;
      }
    }
  }

  .contactsContent {
    .userMessage {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 85%;
      width: 100%;

      margin: 60px auto;
      gap: 5em;

      .form {
        width: auto;
        background-color: #404a3d;
        border: none;
        border-radius: 8px;
        padding: 1em;

        h3 {
          padding: 1em;
          color: white;
          font-size: x-large;
          text-align: center;
        }
        .input {
          .dv1 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1em;
            padding: 20px;
            input {
              padding: 10px;
              outline: none;
              text-align: center;
              border: none;
              border-radius: 8px;
            }
          }
          .dv2 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1em;
            padding: 20px;
            input {
              height: 50px;
              text-align: center;
              border: none;
              border-radius: 8px;
            }
          }
          .dv3 {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            padding: 30px;
            border-radius: 20px;
            textarea {
              padding: 10px;
              outline: none;
              text-align: center;
              border: none;
              border-radius: 8px;
            }
          }
        }

        button {
          display: block;
          margin: 10px auto 50px auto;
          padding: 12px;
          min-width: 250px;
          width: fit-content;
          border: 2px solid #60606070;
          border-radius: 8px;
          background-color: $accent;
          cursor: pointer;
        }
        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }
      }

      .message {
        .messageIcon {
          h3 {
            text-align: center;
            padding: 1em;
          }

          p {
            line-height: 2;
            color: gray;
            font-weight: 400;
          }
          .ic1,
          .ic2,
          .ic3,
          .ic4 {
            display: grid;
            grid-template-rows: repeat(2, 1fr);
            padding: 1em;
            gap: 3px;
            a {
              color: $primary;
            }
            p {
              font-weight: 600;
            }
            a {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
